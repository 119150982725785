import { Container, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../../../assets/logo.png";
import "./Footer.css";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import YouTubeIcon from "@mui/icons-material/YouTube";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-inner">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <div className="footer-logo">
                <img src={Logo} alt="Logo" />
              </div>
              <div className="footer-social">
                <p> LETS STAY CONNECTED </p>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/Businessclassforless/">
                      <FacebookOutlinedIcon />
                    </a>
                  </li>
                  <li>
                    <a href="https://instagram.com/bclass503?igshid=YmMyMTA2M2Y=">
                      <InstagramIcon />
                    </a>
                  </li>
                  {/* <li>
                    <a href="linkedin.com">
                      <LinkedInIcon />
                    </a>
                  </li>
                  <li>
                    <a href="youtube.com">
                      <YouTubeIcon />
                    </a>
                  </li> */}
                </ul>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={2.5}>
              <div className="footer-menu">
                <h4>Company</h4>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/offers">Offers</Link>
                  </li>
                  <li>
                    <Link to="/how-to-book">How to Book</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/reviews">Reviews</Link>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={2.5}>
              <div className="footer-menu">
                <h4>Useful Links</h4>
                <ul>
                  {/* <li>
                    <Link to="/">FAQs</Link>
                  </li> */}
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/refunds-policy">Refunds Policy</Link>
                  </li>
                  {/* <li>
                    <Link to="/privacy">Terms &amp; Conditions</Link>
                  </li> */}
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className="footer-menu">
                <h4>Contact</h4>
                <ul>
                  <li>
                    <Link to="/">
                      {/* <b> Registration Number:</b>  */}
                      BCFL Company Inc.
                    </Link>
                  </li>
                  <li style={{ color: "#ffffff" }}>
                    <Link to="/">
                      {/* <b> Address: </b> */}
                      1800 N Bayshore Dr, Miami, FL 33132
                    </Link>
                  </li>
                  <li>
                    <a href="tel:17862067595">
                      {/* <b>Call: </b> */}
                      +1 (786) 206 7595
                    </a>
                  </li>
                  <li>
                    <a href="/mailto:connect@businessclassforless.com">
                      {/* <b> Mail: </b> */}
                      connect@businessclassforless.com
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
          <div className="footer-bottom">
            <p>All Rights Reserved BCFL</p>
          </div>
        </Container>
      </div>
    </footer>
  );
}
