import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalState } from "./modalTypes";

const initialState: ModalState = {
  type: "",
  data: null,
  open: false,
	loading: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<ModalState>) {
      const { type, open, data } = action.payload;

      state.data = data;
      state.type = type;
      state.open = open;
    },
    closeModal(state) {
      state.type = "";
      state.data = null;
      state.open = false;
    },
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
  },
});

const modalReducer = modalSlice.reducer;

export const modalActions = modalSlice.actions;
export default modalReducer;
