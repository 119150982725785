import RequestCall from "../RequestCall";
import "./modal-form.css";

export default function ModalForm() {
  return (
    <div className="modal-form">
      <div className="modal-header">
        <h3>Request a Call back</h3>
        <p>Be the first to receive updates on our exclusive offers</p>
      </div>
      <RequestCall />
    </div>
  );
}
