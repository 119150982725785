import axios from "axios";
import { log } from "./logger.service";
import { environment } from "environment";

const CancelToken = axios.CancelToken;
let source = CancelToken.source();

axios.defaults.baseURL = environment.API_END_URL;

axios.interceptors.request.use(
  function (config: any) {
    return {
      ...config,
      cancelToken: source.token,
    };
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res: any) => {
    log("http response", res.config.url, res);
    return res;
  },
  (err: any) => {
    log("http err", err, err.message);

    if (err.message === "Network Error") throw err;

    const { code, message } = err.response.data;

    if (code === 401) {
      source.cancel(message);
      setTimeout(() => {
        window.location.assign("/");
        source = CancelToken.source();
      }, 300);
    }

    throw err;
  }
);

const http = {
  get: axios.get,
  put: axios.put,
  post: axios.post,
  delete: axios.delete,
  setJWT: () => {
    axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("token") || "";
  },
  setMultiPart: () => {
    axios.defaults.headers.common["Content-Type"] = "";
  },
  setHeaders: () => {
    // axios.defaults.headers.common["mode"] = "no-cors";
    axios.defaults.headers.common["Access-Control-Allow-Headers"] =
      "Origin, X-Requested-With, Content-Type";
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common["Access-Control-Allow-Method"] =
      "GET, POST, PUT, DELETE, OPTIONS";
    axios.defaults.headers.common["Content-Type"] = "application/text/xml";
    axios.defaults.headers.common["Cache-Control"] = "no-cache";
    axios.defaults.headers.common["Content-Type"] =
      "multipart/form-data; boundary=<calculated when request is sent>";
    axios.defaults.headers.common["Content-Length"] =
      "<calculated when request is sent>";
    axios.defaults.headers.common["Accept"] = "*/*";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
    axios.defaults.headers.common["Connection"] = "keep-alive";
    // axios.defaults.headers.common["Content-type"] =
    //   "multipart/form-data";
    // axios.defaults.headers.common["X-RapidAPI-Host"] =
    //   "travelpayouts-travelpayouts-flight-data-v1.p.rapidapi.com";
    // axios.defaults.headers.common["X-RapidAPI-Key"] =
    //   "a916d4862amsh74e162cf6a4c5fep1f1e94jsnfd2b62703f5e";
  },
};

export default http;
