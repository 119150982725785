import { Call, WhatsApp } from "@mui/icons-material";
import Button from "components/atoms/Button";
import { useAppDispatch } from "redux/hooks";
import { modalActions } from "redux/slices/modal/modalSlice";
import { REQUEST_CALL } from "redux/slices/modal/modalTypes";
import "./floating-button.css";

export default function FloatingButton() {
  const dispatch = useAppDispatch();
  return (
    <>
      <div className="callback-button">
        <Button
          variant="contained"
          color="secondary"
          className="callback-action"
          onClick={() =>
            dispatch(
              modalActions.openModal({
                open: true,
                type: REQUEST_CALL,
                data: "",
              })
            )
          }
          sx={{ color: "#71296f", borderRadius: "50%" }}
        >
          Request <br /> A Call
        </Button>
      </div>

      {/* <div className="callback-button">
        <a href="tel:02079930092" className="callback-action"></a>
      </div> */}

      <div className="callback-button callback-button-left">
        <a href="tel:8475104377" className="callback-action">
          <Call />
        </a>
      </div>
      <div className="callback-button callback-button-right">
        <a href="https://wa.me/8475104377?text=I'm%20interested%20in%20your%20business%20class%20flights%20offers" className="callback-action">
          <WhatsApp />
        </a>
      </div>
    </>
  );
}
