import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import FullPageLoader from "components/templates/FullPageLoader";
import FullScreenLoader from "pages/FullScreenLoader";

const Home = lazy(() => import("pages/Home"));
const About = lazy(() => import("pages/About"));
const Contact = lazy(() => import("pages/Contact"));
const Offers = lazy(() => import("pages/Offers"));
const HowItWorks = lazy(() => import("pages/HowItWorks"));
const Blogs = lazy(() => import("pages/Blogs"));
const Reviews = lazy(() => import("pages/Reviews"));
const FarePage = lazy(() => import("pages/FarePage"));
const NotFound = lazy(() => import("pages/NotFound"));
const LandingPage = lazy(() => import("pages/LandingPage"));
const Thanks = lazy(() => import("pages/Thanks"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));
const RefuncdsPolicy = lazy(() => import("pages/RefundsPolicy"));
export default function AppRoutes() {
  return (
    <Suspense fallback={<FullPageLoader />}>
      <Routes>
        {/* Public Routes */}
        <Route path="/searching" element={<FullScreenLoader />} />
        <Route path="/thanks" element={<Thanks />} />
        <Route path="/flights/:airport" element={<LandingPage />} />
        <Route path="/fares" element={<FarePage />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/how-to-book" element={<HowItWorks />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/refunds-policy" element={<RefuncdsPolicy />} />
        <Route path="/" element={<Home />} />

        {/* Redirect to root or Not Found */}
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}
