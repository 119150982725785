import { Field, reduxForm } from "redux-form";
import InputRedux from "components/molecules/InputRedux";
import { Grid } from "@mui/material";
import Button from "components/atoms/Button";
import PhoneInputRedux from "components/molecules/PhoneInputRedux";

function ContactForm({ handleSubmit }: any) {
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={12}>
            <Field
              name="name"
              label="Full Name"
              color="secondary"
              component={InputRedux}
              variant="filled"
              sx={{
                background:
                  "linear-gradient(95deg, #ffffff66 0%, #ffffff00 100%)",
                backdropFilter: "blur(16px)",
                borderRadius: "8px",
                color: "#ffffff",
                "& .MuiFilledInput-root": {
                  borderRadius: "8px",
                  background:
                    "linear-gradient(270deg, #ffffff66 0%, #ffffff00 100%)",
                },
                "& .MuiFilledInput-input": {
                  color: "#ffffff",
                  padding: "20px 10px 10px 10px",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.275em",
                  fontSize: "1rem",
                  color: "#ffffff",
                  border: "none !important",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#ffffff",
                  fontSize: "18px",
                },
              }}
              InputProps={{ disableUnderline: true }}
            />
          </Grid>{" "}
          <Grid item xs={12} md={12}>
            <Field
              name="email"
              label="Email Address"
              color="secondary"
              component={InputRedux}
              variant="filled"
              sx={{
                background:
                  "linear-gradient(95deg, #ffffff66 0%, #ffffff00 100%)",
                backdropFilter: "blur(16px)",
                borderRadius: "8px",
                color: "#ffffff",
                "& .MuiFilledInput-root": {
                  borderRadius: "8px",
                  background:
                    "linear-gradient(270deg, #ffffff66 0%, #ffffff00 100%)",
                },
                "& .MuiFilledInput-input": {
                  color: "#ffffff",
                  padding: "20px 10px 10px 10px",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.275em",
                  fontSize: "1rem",
                  color: "#ffffff",
                  border: "none !important",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#ffffff",
                  fontSize: "18px",
                },
              }}
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Field
              name="phone"
              label="Phone Number"
              color="secondary"
              component={PhoneInputRedux}
              variant="filled"
              sx={{
                background:
                  "linear-gradient(95deg, #ffffff66 0%, #ffffff00 100%)",
                backdropFilter: "blur(16px)",
                borderRadius: "8px",
                color: "#ffffff",
                "& .MuiFilledInput-root": {
                  borderRadius: "8px",
                  background:
                    "linear-gradient(270deg, #ffffff66 0%, #ffffff00 100%)",
                },
                "& .MuiFilledInput-input": {
                  color: "#ffffff",
                  padding: "20px 10px 10px 10px",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.275em",
                  fontSize: "1rem",
                  color: "#ffffff",
                  border: "none !important",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#ffffff",
                  fontSize: "18px",
                },
              }}
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Field
              name="message"
              label="Message (If any)"
              color="secondary"
              component={InputRedux}
              variant="filled"
              multiline
              rows={4}
              sx={{
                background:
                  "linear-gradient(95deg, #ffffff66 0%, #ffffff00 100%)",
                backdropFilter: "blur(16px)",
                borderRadius: "8px",
                color: "#ffffff",
                "& .MuiFilledInput-root": {
                  borderRadius: "8px",
                  background:
                    "linear-gradient(270deg, #ffffff66 0%, #ffffff00 100%)",
                },
                "& .MuiFilledInput-input": {
                  color: "#ffffff",
                  padding: "20px 10px 10px 10px",
                },
                "& .MuiInputLabel-root": {
                  lineHeight: "1.275em",
                  fontSize: "1rem",
                  color: "#ffffff",
                  border: "none !important",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#ffffff",
                  fontSize: "18px",
                },
              }}
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              sx={{ color: "#ffffff" }}
            >
              GET BEST FARES
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default reduxForm({ form: "ContactForm" })(ContactForm);
