import { useState } from "react";
import useEffectOnce from "hooks/useEffectOnce";
import ToasterService from "services/toaster.service";
import { Alert, Snackbar } from "@mui/material";

export default function Toaster() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("info");
  console.log("Type", type);
  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const successToaster = (content: string) => {
    setOpen(true);
    setMessage(content);
    setType("success");
  };

  const errorToaster = (content: string) => {
    setOpen(true);
    setMessage(content);
    setType("error");
  };

  useEffectOnce(() => ToasterService.subscribe(successToaster, errorToaster));

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
