import { AirportsState } from "./airportsTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: AirportsState = {
  airports: [],
  loading: false,
};

export const airportsSlice = createSlice({
  name: "airports",
  initialState,
  reducers: {
    setAirports: (state, action) => {
      state.loading = false;
      state.airports = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

const airportsReducer = airportsSlice.reducer;

export const airportsActions = airportsSlice.actions;
export default airportsReducer;
