import { FlightsState } from "./flightsTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: FlightsState = {
  flights: [],
  loading: false,
  tripLoading: false,
};

export const flightsSlice = createSlice({
  name: "flights",
  initialState,
  reducers: {
    setFlights: (state, action) => {
      state.loading = false;
      state.flights = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTripLoading: (state, action: PayloadAction<boolean>) => {
      state.tripLoading = action.payload;
    },
  },
});

const flightsReducer = flightsSlice.reducer;

export const flightsActions = flightsSlice.actions;
export default flightsReducer;
