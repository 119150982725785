import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import FlightsService from "services/flights.service";
import ContactForm from "../../../pages/Contact/ContactForm";

export default function RequestCall() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = (values: any) => {
    console.log("Values", values);
    FlightsService.requestCall(values, dispatch, navigate);
  };
  return (
    <div className="form-modal">
      <ContactForm onSubmit={handleSubmit} />
    </div>
  );
}
