import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import modalReducer from "./slices/modal/modalSlice";
import flightsReducer from "./slices/flights/flightsSlice";
import airportsReducer from "./slices/airports/airportsSlice";

const rootReducer = combineReducers({
  form: formReducer,
  modal: modalReducer,
  flights: flightsReducer,
  airports: airportsReducer,
});

export default rootReducer;
