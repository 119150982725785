import "./App.css";

import { store } from "redux/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "routes/AppRoutes";
import Toaster from "components/atoms/Toaster";
import { createTheme, ThemeProvider } from "@mui/material";
import Header from "components/templates/Header";
import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Footer from "components/templates/Footer";
import ResponsiveHeader from "components/templates/ResponsiveHeader";
import FloatingButton from "components/templates/FloatingButton";
import AppModal from "components/templates/AppModal";

const themeOptions = createTheme({
  palette: {
    primary: {
      main: "#71296F",
    },
    secondary: {
      main: "#DEBD5F",
    },
  },
});

export default function App() {

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Provider store={store}>
        <ThemeProvider theme={themeOptions}>
          <BrowserRouter>
            <Toaster />
            <AppModal />
            <FloatingButton />
            <Header />
            <ResponsiveHeader />
            <AppRoutes />
            <Footer />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </LocalizationProvider>
  );
}
