import "./loader.css";
import Logo from "assets/logo.png";

export default function FullScreenLoader() {
  return (
    <div className="Loader">
      <img src={Logo} alt="" />
      <h1>Please wait for the process...</h1>
      <svg
        id="preloader"
        xmlns="http://www.w3.org/2000/svg"
        width="1484"
        height="20"
        viewBox="0 0 1484 20"
      >
        <g data-name="Group 61" transform="translate(-953.5 -1182.5)">
          <line
            id="loading-bar"
            x2="1464"
            transform="translate(963.5 1192.5)"
            fill="none"
            stroke="rgba(255,255,255,0.22)"
            strokeLinecap="round"
            stroke-width="20"
          />
          <line
            id="loading-line-main"
            x2="1464"
            transform="translate(963.5 1192.5)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            stroke-width="20"
          >
            <animate
              attributeName="x2"
              begin="0s"
              dur="3s"
              values="0;0;0;0;0;250;280;400;650;900;900;950;1000;1050;1200;1464"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </line>
        </g>
      </svg>
      <p>"We're searching for the best business class flights for you"</p>
    </div>
  );
}
