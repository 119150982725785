import "./full-page-loader.css";
import Logo from "assets/logo.png";

export default function FullPageLoader() {
  return (
    <div className="full-page-loader">
      <img src={Logo} alt="" />
    </div>
  );
}
