import React from "react";
import { Menu } from "@mui/icons-material";
import {
  AppBar,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import LogoImg from "../../../assets/logo.png";
import { Link } from "react-router-dom";

const links = [
  {
    text: "About Us",
    link: "/about",
  },
  {
    text: "Offers",
    link: "/offers",
  },
  {
    text: "How to Book",
    link: "/how-to-book",
  },
  {
    text: "Blog",
    link: "/blog",
  },
  {
    text: "Reviews",
    link: "/reviews",
  },
  {
    text: "Contact Us",
    link: "/contact",
  },
];

const drawerWidth = 300;

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    flexShrink: 0,
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#71296F !important",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  logoImg: {
    width: "125px",
    paddingTop: "8px",
  },
  mainMenu: {
    marginTop: "100px !important",
  },
  menuLinks: {
    textDecoration: "none",
    color: "#ffffff",
  },
}));

export default function App() {
  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            className={classes.menuButton}
          >
            <Menu fontSize="large" />
          </IconButton>
          <Link to="/">
            <img src={LogoImg} alt="Logo" className={classes.logoImg} />
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant={isMdUp ? "permanent" : "temporary"}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        sx={{ zIndex: 998 }}
      >
        <div className={classes.toolbar} />
        <List className={classes.mainMenu}>
          {links.map((item) => (
            <ListItem button key={item.text}>
              <Link
                to={item.link}
                className={classes.menuLinks}
                onClick={() => setOpen(false)}
              >
                <ListItemText primary={item.text} color="primary" />
              </Link>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
}
