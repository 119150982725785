import { useAppDispatch, useAppSelector } from "redux/hooks";
import CircleLoader from "components/atoms/CircleLoader";
import { Dialog } from "@mui/material";
import { REQUEST_CALL } from "redux/slices/modal/modalTypes";
import { modalActions } from "redux/slices/modal/modalSlice";
import ModalForm from "../ModalForm";
import CloseIcon from "@mui/icons-material/Close";
function AppModal() {
  const dispatch = useAppDispatch();
  const { type, open, loading } = useAppSelector((state) => state.modal);

  return (
    <Dialog
      // fullScreen={fullScreen}
      open={open}
      onClose={() => dispatch(modalActions.closeModal())}
      aria-labelledby="responsive-dialog-title"
    >
      <CloseIcon
        color="secondary"
        style={{
          position: "absolute",
          top: "15px",
          right: "15px",
          cursor: "pointer",
        }}
        onClick={() => dispatch(modalActions.closeModal())}
      />
      {loading && <CircleLoader />}
      {type === REQUEST_CALL ? <ModalForm /> : ""}
    </Dialog>
  );
}
export default AppModal;
