import { Link, useLocation } from "react-router-dom";
import LogoImg from "../../../assets/logo.png";
import UsaIcon from "../../../assets/united-states.png";
import ImageA from "../../../assets/payments/Image6.png";
import ImageB from "../../../assets/payments/Image2.png";
import ImageC from "../../../assets/payments/Image3.png";
import "./header.css";

export default function Header() {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="header">
      <div className="top-bar">
        <div className="header-images">
          <div style={{ marginRight: "30px" }}>24/7 Live Service</div>
          <img src={ImageA} alt="aita" />
          <img src={ImageB} alt="Trust" style={{ height: "30px" }} />
          <img src={ImageC} alt="BBB" style={{ height: "24px" }} />
        </div>
        <ul className="header-numbers">
          <li>
            <p>Call us for best fares</p>
          </li>
          {/* <li>
            <a href="tel:02079930092">
              <img src={UkIcon} alt="" />
              <p>0207 993 0092</p>
            </a>
          </li> */}
          <li>
            <a href="tel:17862067595">
              <img src={UsaIcon} alt="" />
              <p>(786) 206 7595</p>
            </a>
          </li>
        </ul>
      </div>
      <header>
        <nav className="navbar">
          <ul
            className={`navbar-inner ${pathname === "/about" && "navbar-dark"}`}
          >
            <li className="nav-list">
              <Link to="/about">About Us</Link>
            </li>
            <li className="nav-list">
              <Link to="/offers">Offers</Link>
            </li>
            <li className="nav-list">
              <Link to="/how-to-book">How to Book</Link>
            </li>
            <li className="nav-list">
              <Link to="/">
                <img src={LogoImg} alt="Logo" />
              </Link>
            </li>
            <li className="nav-list">
              <Link to="/blog">Blog</Link>
            </li>
            <li className="nav-list">
              <Link to="/reviews">reviews</Link>
            </li>
            <li className="nav-list">
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
}
